body,
.card,
.card-header,
.hero {
  background-color: var(--skin-background-color-1);
  font-family: var(--skin-body-font);
  color: var(--skin-main-text-color-1); }

.hero {
  border-bottom-color: var(--skin-background-color-1); }

.main-menu {
  background-color: var(--skin-banner-background-color-1); }

.banner-color {
  background-color: var(--skin-banner-background-color-1); }

.header-banner {
  background-color: var(--skin-banner-background-color-2);
  color: var(--skin-banner-text-color-1); }

.header-banner .close-button .close {
  background-color: var(--skin-primary-color-1); }

h1.header.page-title,
h1.header.page-title::before {
  background-color: var(--skin-heading-color-1);
  color: var(--skin-heading-color-1-invert); }

.product-tile {
  background-color: var(--skin-background-color-1);
  border: 10px solid var(--skin-border-color-1); }

.refinements ul li button {
  color: var(--skin-primary-color-1); }

.custom-select {
  background-color: var(--skin-selectbox-background-color-1);
  color: var(--skin-selectbox-text-color-1); }

a {
  color: var(--skin-primary-color-1); }

header ~ #maincontent .container a:not(.btn-primary, .btn-outline-primary) {
  color: var(--skin-primary-color-1); }

.price {
  color: var(--skin-price-1); }

.navbar,
.navbar .nav-item,
.navbar .nav-item a,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a,
.dropdown-menu,
.dropdown-menu a {
  color: var(--skin-menu-color-1); }
  @media (min-width: 769px) {
    .navbar,
    .navbar .nav-item,
    .navbar .nav-item a,
    .navbar .nav-item.dropdown,
    .navbar .nav-item.dropdown a,
    .dropdown-menu,
    .dropdown-menu a {
      color: var(--skin-menu-color-1-invert); } }

.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown .dropdown-menu.show {
  color: var(--skin-selectbox-text-color-1); }

.navbar .nav-item.dropdown .dropdown-menu.show {
  background-color: var(--skin-selectbox-background-color-1); }
  .navbar .nav-item.dropdown .dropdown-menu.show a,
  .navbar .nav-item.dropdown .dropdown-menu.show .nav-item {
    color: var(--skin-selectbox-text-color-1); }

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
  background-color: var(--skin-menu-color-1-invert);
  color: var(--skin-menu-color-1) !important; }

.btn-primary {
  background-color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color-1);
  color: var(--skin-primary-color-invert-1); }

.btn-primary:hover {
  color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color-1);
  background-color: var(--skin-primary-color-invert-1); }

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color);
  color: var(--skin-primary-color-invert-1);
  opacity: 0.5; }

.btn-outline-primary {
  color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color-1); }

.btn-outline-primary:hover {
  color: var(--skin-primary-color-invert-1);
  background-color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color-1); }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--skin-header-font), sans-serif; }

a {
  color: var(--skin-link-color-1); }

.color-primary {
  color: #282828 !important; }

.color-secondary {
  color: transparent !important; }

.color-white {
  color: #fff !important; }

.color-black {
  color: #282828 !important; }

.color-success {
  color: #008827 !important; }

.color-warning {
  color: #F8C550 !important; }

.color-danger {
  color: #ED3535 !important; }

.color-grey1 {
  color: #f9f9f9 !important; }

.color-grey2 {
  color: #eee !important; }

.color-grey3 {
  color: #ccc !important; }

.color-grey4 {
  color: #999 !important; }

.color-grey5 {
  color: #666 !important; }

.color-grey6 {
  color: #444 !important; }

.color-grey7 {
  color: #222 !important; }

.color-grey8 {
  color: #333 !important; }

.color-black80 {
  color: #535353 !important; }

.color-black60 {
  color: #7E7E7E !important; }

.color-black40 {
  color: #A9A9A9 !important; }

.color-black20 {
  color: #D4D4D4 !important; }

.color-black10 {
  color: #EAEAEA !important; }

.color-primary-white {
  color: #FFFFFF !important; }

.color-warm-gray {
  color: #F6F3F2 !important; }

.color-warm-background {
  color: #F2ECE5 !important; }

.color-straw-background {
  color: #F8EED5 !important; }

.color-straw-foreground {
  color: #9D4324 !important; }

.color-vine-background {
  color: #E5D1CD !important; }

.color-vine-foreground {
  color: #5B3B42 !important; }

.color-pine-background {
  color: #F2E6DF !important; }

.color-pine-foreground {
  color: #195650 !important; }

.fill-primary {
  fill: #282828 !important; }

.fill-secondary {
  fill: transparent !important; }

.fill-white {
  fill: #fff !important; }

.fill-black {
  fill: #282828 !important; }

.fill-success {
  fill: #008827 !important; }

.fill-warning {
  fill: #F8C550 !important; }

.fill-danger {
  fill: #ED3535 !important; }

.fill-grey1 {
  fill: #f9f9f9 !important; }

.fill-grey2 {
  fill: #eee !important; }

.fill-grey3 {
  fill: #ccc !important; }

.fill-grey4 {
  fill: #999 !important; }

.fill-grey5 {
  fill: #666 !important; }

.fill-grey6 {
  fill: #444 !important; }

.fill-grey7 {
  fill: #222 !important; }

.fill-grey8 {
  fill: #333 !important; }

.fill-black80 {
  fill: #535353 !important; }

.fill-black60 {
  fill: #7E7E7E !important; }

.fill-black40 {
  fill: #A9A9A9 !important; }

.fill-black20 {
  fill: #D4D4D4 !important; }

.fill-black10 {
  fill: #EAEAEA !important; }

.fill-primary-white {
  fill: #FFFFFF !important; }

.fill-warm-gray {
  fill: #F6F3F2 !important; }

.fill-warm-background {
  fill: #F2ECE5 !important; }

.fill-straw-background {
  fill: #F8EED5 !important; }

.fill-straw-foreground {
  fill: #9D4324 !important; }

.fill-vine-background {
  fill: #E5D1CD !important; }

.fill-vine-foreground {
  fill: #5B3B42 !important; }

.fill-pine-background {
  fill: #F2E6DF !important; }

.fill-pine-foreground {
  fill: #195650 !important; }

html {
  font-size: 1rem; }

h1,
.h1 {
  font-size: 4.375rem; }

h1,
.h1 {
  line-height: 4.0625rem; }

@media (min-width: 1025px) {
  h1,
  .h1 {
    font-size: 10.625rem; }
  h1,
  .h1 {
    line-height: 9.375rem; } }

h2,
.h2 {
  font-size: 2.8125rem; }

h2,
.h2 {
  line-height: 2.9375rem; }

@media (min-width: 1025px) {
  h2,
  .h2 {
    font-size: 6.875rem; }
  h2,
  .h2 {
    line-height: 6.875rem; } }

h3,
.h3 {
  font-size: 2.375rem; }

h3,
.h3 {
  line-height: 2.5rem; }

@media (min-width: 1025px) {
  h3,
  .h3 {
    font-size: 5.625rem; }
  h3,
  .h3 {
    line-height: 5.625rem; } }

h4,
.h4 {
  font-size: 2.25rem; }

h4,
.h4 {
  line-height: 2.25rem; }

@media (min-width: 1025px) {
  h4,
  .h4 {
    font-size: 4.25rem; }
  h4,
  .h4 {
    line-height: 4.25rem; } }

h5,
.h5 {
  font-size: 1.625rem; }

h5,
.h5 {
  line-height: 1.75rem; }

@media (min-width: 1025px) {
  h5,
  .h5 {
    font-size: 2.875rem; }
  h5,
  .h5 {
    line-height: 3rem; } }

h6,
.h6 {
  font-size: 1.375rem; }

h6,
.h6 {
  line-height: 1.375rem; }

@media (min-width: 1025px) {
  h6,
  .h6 {
    font-size: 1.875rem; }
  h6,
  .h6 {
    line-height: 2rem; } }

text-xbig,
.text-xbig {
  font-size: 1.125rem; }

text-xbig,
.text-xbig {
  line-height: 1.5rem; }

@media (min-width: 1025px) {
  text-xbig,
  .text-xbig {
    font-size: 1.25rem; }
  text-xbig,
  .text-xbig {
    line-height: 1.75rem; } }

text-big,
.text-big {
  font-size: 1rem; }

text-big,
.text-big {
  line-height: 1.375rem; }

@media (min-width: 1025px) {
  text-big,
  .text-big {
    font-size: 1rem; }
  text-big,
  .text-big {
    line-height: 1.375rem; } }

text-normal,
.text-normal {
  font-size: 0.8125rem; }

text-normal,
.text-normal {
  line-height: 1.125rem; }

@media (min-width: 1025px) {
  text-normal,
  .text-normal {
    font-size: 0.8125rem; }
  text-normal,
  .text-normal {
    line-height: 1.125rem; } }

text-small,
.text-small {
  font-size: 0.75rem; }

text-small,
.text-small {
  line-height: 1rem; }

@media (min-width: 1025px) {
  text-small,
  .text-small {
    font-size: 0.75rem; }
  text-small,
  .text-small {
    line-height: 1rem; } }

text-xsmall,
.text-xsmall {
  font-size: 0.6875rem; }

text-xsmall,
.text-xsmall {
  line-height: 1rem; }

@media (min-width: 1025px) {
  text-xsmall,
  .text-xsmall {
    font-size: 0.6875rem; }
  text-xsmall,
  .text-xsmall {
    line-height: 1rem; } }

label-big,
.label-big {
  font-size: 0.8125rem; }

label-big,
.label-big {
  line-height: 1.125rem; }

label-big,
.label-big {
  letter-spacing: 0.1875rem; }

@media (min-width: 1025px) {
  label-big,
  .label-big {
    font-size: 0.8125rem; }
  label-big,
  .label-big {
    line-height: 1.125rem; }
  label-big,
  .label-big {
    letter-spacing: 0.1875rem; } }

label-small,
.label-small {
  font-size: 0.6875rem; }

label-small,
.label-small {
  line-height: 0.875rem; }

label-small,
.label-small {
  letter-spacing: 0.06875rem; }

@media (min-width: 1025px) {
  label-small,
  .label-small {
    font-size: 0.6875rem; }
  label-small,
  .label-small {
    line-height: 0.875rem; }
  label-small,
  .label-small {
    letter-spacing: 0.06875rem; } }

a-big,
.a-big {
  font-size: 0.8125rem; }

a-big,
.a-big {
  line-height: 1.125rem; }

@media (min-width: 1025px) {
  a-big,
  .a-big {
    font-size: 1rem; }
  a-big,
  .a-big {
    line-height: 1.25rem; } }

a-normal,
.a-normal {
  font-size: 0.8125rem; }

a-normal,
.a-normal {
  line-height: 1.125rem; }

@media (min-width: 1025px) {
  a-normal,
  .a-normal {
    font-size: 0.8125rem; }
  a-normal,
  .a-normal {
    line-height: 1.125rem; } }

a-small,
.a-small {
  font-size: 0.8125rem; }

a-small,
.a-small {
  line-height: 1.125rem; }

@media (min-width: 1025px) {
  a-small,
  .a-small {
    font-size: 0.6875rem; }
  a-small,
  .a-small {
    line-height: 0.875rem; } }

a {
  color: #282828;
  text-decoration: underline;
  font-weight: 700; }
  a.no-underline {
    text-decoration: none; }
  a.a-big, a.a-small, a.a-normal {
    font-weight: 400; }

.text-decoration-underline {
  text-decoration: underline; }

/*

$btn-font-maps: (
    xs: (
        "font-size": 12,
        "line-height": 16,
    ),
    lg: (
        "font-size": 13,
        "line-height": 16,
    )
);

$btn-font-map: map-get($btn-font-maps, xs);
@include responsive-font-maps(btn, $btn-font-maps, $grid-breakpoints);

*/
.btn:not(.btn-link) {
  transition: none;
  font-size: 0.75rem;
  border-color: #282828;
  background-color: #282828;
  font-weight: 600; }
  .btn:not(.btn-link):focus {
    box-shadow: none; }
  @media (min-width: 1025px) {
    .btn:not(.btn-link) {
      font-size: 0.8125rem; } }
  .btn:not(.btn-link).hover, .btn:not(.btn-link):hover {
    border-color: #535353;
    background-color: #535353;
    color: #fff; }
  .btn:not(.btn-link).disabled, .btn:not(.btn-link):disabled, .btn:not(.btn-link).select-variant {
    border-color: #D4D4D4;
    background-color: #D4D4D4; }
  .btn:not(.btn-link):not(:disabled):not(.disabled):active, .btn:not(.btn-link):not(:disabled):not(.disabled).active {
    background-color: #535353; }
  .btn:not(.btn-link).btn-secondary {
    border: 0.0625rem solid #282828;
    color: #282828;
    background-color: #fff; }
    .btn:not(.btn-link).btn-secondary.hover, .btn:not(.btn-link).btn-secondary:hover {
      color: #282828;
      border-color: #7E7E7E;
      background-color: #fff; }
    .btn:not(.btn-link).btn-secondary.disabled, .btn:not(.btn-link).btn-secondary:disabled {
      color: #7E7E7E;
      border-color: #A9A9A9;
      background-color: #EAEAEA; }

button.btn-link {
  font-family: "Graphik", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  padding-bottom: 0.3125rem;
  color: #282828; }
  @media (min-width: 1025px) {
    button.btn-link {
      font-size: 28px;
      line-height: 25px; } }
  button.btn-link.btn-link-icon {
    font-family: "Beausite", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-weight: 400; }
    button.btn-link.btn-link-icon:before {
      content: "";
      background-color: #282828;
      width: 40px;
      height: 40px;
      display: inline-block;
      border-radius: 100%;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='50' height='28' viewBox='0 0 50 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.499997 14L48.5 14L38.09 26.21C38.0131 26.3187 37.9825 26.4536 38.005 26.5849C38.0275 26.7161 38.1013 26.8331 38.21 26.91C38.3187 26.9869 38.4536 27.0175 38.5849 26.995C38.7161 26.9725 38.8331 26.8987 38.91 26.79L49.91 13.79C49.9657 13.7032 49.9968 13.603 50 13.5C49.9947 13.4081 49.9673 13.3189 49.92 13.24L38.92 0.239997C38.8767 0.167073 38.8153 0.106626 38.7417 0.064559C38.6681 0.0224924 38.5848 0.00024505 38.5 -1.00536e-06C38.3965 0.000479637 38.2956 0.0318155 38.21 0.0899991C38.1559 0.127892 38.1099 0.176122 38.0746 0.231902C38.0392 0.287682 38.0153 0.34991 38.0041 0.414992C37.993 0.480075 37.9948 0.546721 38.0096 0.611087C38.0243 0.675452 38.0516 0.736258 38.09 0.79L48.53 13L0.53 13C0.397393 13 0.270215 13.0527 0.176446 13.1464C0.0826771 13.2402 0.03 13.3674 0.03 13.5C0.0299999 13.6326 0.0826771 13.7598 0.176446 13.8535C0.270215 13.9473 0.397393 14 0.53 14L0.499997 14Z' fill='white'/%3E%3C/svg%3E");
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      vertical-align: middle;
      transition: all 0.25s;
      transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
      margin-right: 4px; }
      @media (min-width: 1025px) {
        button.btn-link.btn-link-icon:before {
          width: 50px;
          height: 50px;
          margin-right: 8px; } }
    button.btn-link.btn-link-icon.invert:before {
      background-color: #fff;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='50' height='28' viewBox='0 0 50 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.499997 14L48.5 14L38.09 26.21C38.0131 26.3187 37.9825 26.4536 38.005 26.5849C38.0275 26.7161 38.1013 26.8331 38.21 26.91C38.3187 26.9869 38.4536 27.0175 38.5849 26.995C38.7161 26.9725 38.8331 26.8987 38.91 26.79L49.91 13.79C49.9657 13.7032 49.9968 13.603 50 13.5C49.9947 13.4081 49.9673 13.3189 49.92 13.24L38.92 0.239997C38.8767 0.167073 38.8153 0.106626 38.7417 0.064559C38.6681 0.0224924 38.5848 0.00024505 38.5 -1.00536e-06C38.3965 0.000479637 38.2956 0.0318155 38.21 0.0899991C38.1559 0.127892 38.1099 0.176122 38.0746 0.231902C38.0392 0.287682 38.0153 0.34991 38.0041 0.414992C37.993 0.480075 37.9948 0.546721 38.0096 0.611087C38.0243 0.675452 38.0516 0.736258 38.09 0.79L48.53 13L0.53 13C0.397393 13 0.270215 13.0527 0.176446 13.1464C0.0826771 13.2402 0.03 13.3674 0.03 13.5C0.0299999 13.6326 0.0826771 13.7598 0.176446 13.8535C0.270215 13.9473 0.397393 14 0.53 14L0.499997 14Z' fill='black'/%3E%3C/svg%3E"); }
    button.btn-link.btn-link-icon:hover:before, button.btn-link.btn-link-icon.hover:before {
      background-position: 70% 50%;
      background-color: #535353; }
    button.btn-link.btn-link-icon:hover.invert, button.btn-link.btn-link-icon.hover.invert {
      color: #EAEAEA; }
      button.btn-link.btn-link-icon:hover.invert:before, button.btn-link.btn-link-icon.hover.invert:before {
        background-color: #EAEAEA; }
  button.btn-link .btn-link-underline {
    border-bottom: 0.0625rem solid #282828;
    transition: border-color 0.1s;
    padding-bottom: 2px; }
    @media (min-width: 1025px) {
      button.btn-link .btn-link-underline {
        padding-bottom: 5px; } }
  button.btn-link.hover, button.btn-link:hover {
    text-decoration: none;
    color: #535353; }
    button.btn-link.hover a, button.btn-link:hover a {
      color: #535353; }
    button.btn-link.hover .btn-link-underline, button.btn-link:hover .btn-link-underline {
      border-color: #535353; }
    button.btn-link.hover svg, button.btn-link:hover svg {
      fill: #D4D4D4; }
  button.btn-link:focus {
    box-shadow: none; }
  button.btn-link i {
    margin-right: 0.3125rem; }
  button.btn-link.invert {
    color: #fff; }
    button.btn-link.invert .btn-link-underline {
      border-color: #fff; }
    button.btn-link.invert:hover, button.btn-link.invert.hover {
      color: #EAEAEA; }
      button.btn-link.invert:hover .btn-link-underline, button.btn-link.invert.hover .btn-link-underline {
        border-color: #EAEAEA; }
  button.btn-link.big-cta {
    font-size: 18px;
    line-height: 19px; }
    @media (min-width: 1025px) {
      button.btn-link.big-cta {
        font-size: 40px;
        line-height: 45px; } }
    button.btn-link.big-cta .btn-link-underline {
      padding-bottom: 2px; }
    button.btn-link.big-cta:before {
      margin-right: 6px;
      width: 60px;
      height: 60px; }
      @media (min-width: 1025px) {
        button.btn-link.big-cta:before {
          width: 110px;
          height: 110px;
          margin-right: 12px; } }
  button.btn-link.small-cta {
    font-size: 15px;
    line-height: 18px; }
    @media (min-width: 1025px) {
      button.btn-link.small-cta {
        font-size: 16px;
        line-height: 20px; } }
    button.btn-link.small-cta .btn-link-underline {
      text-decoration: underline;
      padding-bottom: 0px;
      border-bottom: 0px; }
  button.btn-link.mini-cta {
    font-size: 13px;
    line-height: 18px; }
    @media (min-width: 1025px) {
      button.btn-link.mini-cta {
        font-size: 13px;
        line-height: 22px; } }
    button.btn-link.mini-cta .btn-link-underline {
      text-decoration: underline;
      padding-bottom: 0px;
      border-bottom: 0px; }

button.btn-dropdown {
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent; }
  button.btn-dropdown i {
    margin-left: 0.3125rem; }

.hamburger-box {
  position: relative;
  width: 1.25rem;
  height: 1.25rem; }
  .hamburger-box div.btn-hamburger {
    position: absolute;
    top: 0;
    width: 100%;
    height: 0.125rem;
    background-color: #282828;
    padding: 0;
    border: none;
    transition: background-color 0s linear 0.13s; }
    .hamburger-box div.btn-hamburger::before, .hamburger-box div.btn-hamburger::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.125rem;
      background-color: #282828;
      border-radius: 1.5625rem;
      top: 10px;
      transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger-box div.btn-hamburger::before {
      top: 0.5rem; }
    .hamburger-box div.btn-hamburger::after {
      top: 1rem; }
    .hamburger-box div.btn-hamburger.open {
      background-color: transparent !important; }
      .hamburger-box div.btn-hamburger.open::before, .hamburger-box div.btn-hamburger.open::after {
        top: 0;
        width: 1.375rem; }
      .hamburger-box div.btn-hamburger.open::before {
        transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
        transform: translate3d(0, 10px, 0) rotate(45deg); }
      .hamburger-box div.btn-hamburger.open::after {
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
        transform: translate3d(0, 10px, 0) rotate(-45deg); }

.custom-control {
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem; }
  .custom-control-label::before {
    border: 0.0625rem solid #7E7E7E; }
  .custom-control-helper {
    font-size: 0.75rem;
    color: #535353;
    position: absolute;
    left: 0;
    margin-bottom: 0.625rem; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #008827;
    background-color: #008827;
    border: 0.0625rem solid #282828; }
  .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
    border-color: #D4D4D4;
    background-color: #EAEAEA; }
  .custom-control-input[disabled] ~ .custom-control-label::after, .custom-control-input:disabled ~ .custom-control-label::after {
    background-color: #F6F3F2; }
  .custom-control-input.is-invalid ~ .custom-control-label {
    color: #282828; }
  .custom-control-input.is-invalid ~ .custom-control-label::after {
    background-color: #ED3535; }
  .custom-control-input.is-invalid ~ .custom-control-helper {
    color: #ED3535; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #A9A9A9; }
  .custom-control-input:disabled ~ .custom-control-helper {
    color: #D4D4D4; }

.custom-checkbox .custom-control-label {
  font-size: 0.8125rem;
  text-transform: none; }
  .custom-checkbox .custom-control-label::before {
    background: #fff;
    border: 0.0625rem solid #7E7E7E;
    border-radius: 0;
    top: 0; }
  .custom-checkbox .custom-control-label::after {
    top: 0; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .custom-checkbox .custom-control-input.checked ~ .custom-control-label::before {
  background-color: #fff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after, .custom-checkbox .custom-control-input.checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3L0 4L4 8L11 1L10 0L4 6L1 3Z' fill='%23282828'/%3E%3C/svg%3E") !important; }

.custom-checkbox .custom-control-input.is-invalid ~ .custom-control-label::after {
  background-color: #fff;
  border: 0.0625rem solid #ED3535; }

.custom-checkbox .custom-control-input:disabled ~ .custom-control-label::after {
  border: 0.0625rem solid #D4D4D4; }

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='radio'] {
    --active: #fff;
    --active-inner: #101010;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #101010;
    --border-hover: #101010;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 0.0625rem solid var(--bc, var(--border));
    background: var(--b, var(--background)); }
    input[type='radio']:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      opacity: var(--o, 0);
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background: var(--active-inner);
      transform: scale(0.6); }
    input[type='radio']:checked {
      --b: var(--active);
      --bc: var(--border);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      --o: 1; } }

/*
    After Checking that the radio buttons in PDP (pod products) works well, we can delete all the below code
*/
.custom-control.custom-radio {
  font-size: 13px;
  line-height: 18px; }
  .custom-control.custom-radio input {
    display: none; }
  .custom-control.custom-radio input:checked ~ .custom-control-label::after {
    background-image: none;
    content: "";
    width: 0.625rem;
    height: 0.625rem;
    background-color: #282828;
    border-radius: 100%;
    position: absolute;
    top: 0.25rem;
    left: -1.375rem; }
  .custom-control.custom-radio .custom-control-label::before {
    top: 0;
    width: 1.125rem;
    height: 1.125rem;
    border: 0.0625rem solid #282828;
    background: none;
    background-color: #fff; }
  .custom-control.custom-radio input.is-invalid ~ .custom-control-label::before {
    border: 0.0625rem solid #ED3535;
    background: #fff; }
  .custom-control.custom-radio input.is-invalid ~ .custom-control-label::after {
    background-color: #fff;
    border-radius: 1.5625rem;
    border: 0.0625rem solid #ED3535;
    top: 0; }
  .custom-control.custom-radio input.is-invalid:checked ~ .custom-control-label::after {
    background-color: #ED3535;
    border-radius: 1.5625rem;
    border: 0.0625rem solid #ED3535;
    top: 0.25rem; }
  .custom-control.custom-radio input:disabled ~ .custom-control-label::after {
    border-radius: 1.5625rem;
    background-color: transparent; }
  .custom-control.custom-radio input:disabled ~ .custom-control-label::before {
    border-color: #EAEAEA; }

.custom-control-pdp input {
  position: static; }

.custom-control-pdp .custom-control-label {
  position: static; }

.custom-control-pdp.custom-radio-pdp input:checked ~ .custom-control-label::after {
  background-image: none;
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  background-color: #282828;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(50%); }

.custom-control-pdp.custom-radio-pdp .custom-control-label::before {
  border: 0.125rem solid #282828;
  background: none;
  background-color: #fff;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%); }

.custom-control-pdp.custom-radio-pdp input.is-invalid ~ .custom-control-label::after {
  background-color: #fff;
  border-radius: 1.5625rem;
  border: 0.125rem solid #ED3535; }

.custom-control-pdp.custom-radio-pdp input:disabled ~ .custom-control-label::after {
  border-radius: 1.5625rem;
  background-color: transparent; }

.custom-control-pdp.custom-radio-pdp input:disabled ~ .custom-control-label::before {
  border-color: #EAEAEA; }

.form-group {
  position: relative;
  margin-bottom: 1.4375rem;
  /*

    PLEASE CHECK FURTHER, IF UNCOMMENTED FIELDS ARE ALWAYS "ACTIVE"

*/
  /*      input:valid {
        & ~ .form-control-label {
            top: rem-calc(8);
            font-size: rem-calc(11);
            transition: 0.3s;
            &__void {
                top: rem-calc(15);
                font-size: rem-calc(12);
                transition: 0.3s; 
            }
            color: green;
        }
    }
*/ }
  .form-group .form-control-helper {
    color: #535353;
    top: 0.0625rem;
    left: 0.8125rem;
    position: relative;
    font-size: 0.6875rem; }
  .form-group .form-control {
    font-size: 0.8125rem;
    padding-top: 1.5rem;
    padding-bottom: 0.375rem; }
    .form-group .form-control:disabled {
      border-color: #D4D4D4;
      background-color: #F6F3F2;
      pointer-events: none; }
      .form-group .form-control:disabled ~ .form-control-label,
      .form-group .form-control:disabled ~ .form-control-helper {
        color: #A9A9A9; }
      .form-group .form-control:disabled:-ms-input-placeholder {
        color: #EAEAEA; }
      .form-group .form-control:disabled::placeholder {
        color: #EAEAEA; }
    .form-group .form-control.hover, .form-group .form-control:hover {
      border-color: #A9A9A9; }
    .form-group .form-control.focus {
      border-color: #A9A9A9; }
    .form-group .form-control.is-invalid {
      background-color: #fff;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='10' cy='10' rx='10' ry='10' fill='%23ED3535'/%3E%3Crect x='9' y='13' width='2' height='2' fill='white'/%3E%3Crect x='9' y='5' width='2' height='6' fill='white'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 0.8125rem top 0.8125rem;
      background-size: 20px; }
      .form-group .form-control.is-invalid ~ .form-group .form-control.is-invalid-label,
      .form-group .form-control.is-invalid ~ .form-control-helper {
        color: #ED3535; }
    .form-group .form-control__transparent {
      border: none;
      border-bottom: 1px solid #282828;
      background-color: transparent;
      position: relative;
      font-size: 30px;
      padding-bottom: 25px;
      font-family: "Beausite", "Helvetica Neue", Helvetica, Arial, sans-serif; }
      .form-group .form-control__transparent ~ .btn-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px; }
      @media (max-width: 1024.98px) {
        .form-group .form-control__transparent {
          font-size: 22px;
          padding-bottom: 15px; } }
  .form-group .form-control-label {
    position: absolute;
    top: 0.9375rem;
    left: 0.75rem;
    font-size: 0.8125rem; }
    .form-group .form-control-label__active {
      top: 0.5rem;
      font-size: 0.6875rem;
      transition: 0.3s;
      color: #7E7E7E; }
  .form-group input[value=""]:not(:required) ~ .form-control-label:not(.form-control-label__active) {
    top: 0.375rem;
    font-size: 0.75rem;
    transition: 0.3s; }
  .form-group input:-webkit-autofill ~ .form-control-label {
    top: 0.5rem;
    font-size: 0.6875rem;
    transition: 0.3s;
    color: #7E7E7E; }
  .form-group .clear-text-input {
    position: absolute;
    right: 0.8125rem;
    top: 0.8125rem;
    width: 1.25rem;
    height: 1.25rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='10' cy='10' rx='10' ry='10' fill='%23A9A9A9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.78567 13.9866L13.9864 6.78577L13.2007 6.00009L6 13.2009L6.78567 13.9866Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.2008 13.9866L6.00006 6.78577L6.78573 6.00009L13.9865 13.2009L13.2008 13.9866Z' fill='white'/%3E%3C/svg%3E");
    cursor: pointer;
    z-index: 2; }

.input-group input[type=number] {
  appearance: textfield;
  margin: 0; }

.input-group input[type=number]::-webkit-inner-spin-button,
.input-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.input-group input:focus {
  border: none;
  border-top: solid 0.125rem #ccc;
  border-bottom: solid 0.125rem #ccc; }

.input-group .input-group-prepend {
  width: 3.125rem; }
  .input-group .input-group-prepend button {
    font-size: 1.875rem;
    width: 100%;
    padding: 0;
    background: #fff;
    border: solid 0.125rem #ccc;
    border-right: none; }
    .input-group .input-group-prepend button:hover {
      color: #7E7E7E;
      background: #fff;
      border: solid 0.125rem #ccc;
      border-right: none; }
    .input-group .input-group-prepend button:focus {
      box-shadow: none; }

.input-group .input-group-append {
  width: 3.125rem; }
  .input-group .input-group-append button {
    font-size: 1.875rem;
    width: 100%;
    padding: 0;
    background: #fff;
    border: solid 0.125rem #ccc;
    border-left: none; }
    .input-group .input-group-append button:hover {
      color: #7E7E7E;
      background: #fff;
      border: solid 0.125rem #ccc;
      border-left: none; }
    .input-group .input-group-append button:focus {
      box-shadow: none; }

.form-group-select {
  margin-bottom: 1rem;
  position: relative;
  /*
        select:valid ~ .form-control-label {
            top: rem-calc(8);
            font-size: rem-calc(11);
        }
*/ }
  .form-group-select .custom-select-helper {
    color: #535353;
    top: 0.0625rem;
    left: 0.8125rem;
    position: relative;
    font-size: 0.6875rem; }
  .form-group-select .form-control-label {
    position: absolute;
    top: 0.9375rem;
    left: 0.75rem;
    font-size: 0.75rem;
    transition: 0.3s ease all;
    pointer-events: none; }
  .form-group-select .scaleDown {
    top: 0.5rem;
    font-size: 0.6875rem;
    color: #7E7E7E; }
  .form-group-select select.custom-select,
  .form-group-select select {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='13' height='7' viewBox='0 0 13 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5004 7.00103C6.43487 7.00183 6.36989 6.9889 6.30965 6.96306C6.24941 6.93723 6.19524 6.89907 6.15062 6.85103L0.154354 0.85103C0.106553 0.80614 0.0684545 0.751923 0.0424105 0.691725C0.0163664 0.631527 0.00292969 0.566627 0.00292969 0.50103C0.00292969 0.435434 0.0163664 0.370533 0.0424105 0.310336C0.0684545 0.250138 0.106553 0.19592 0.154354 0.15103C0.199216 0.1032 0.2534 0.0650773 0.31356 0.0390171C0.37372 0.0129568 0.438581 -0.000488281 0.504136 -0.000488281C0.569692 -0.000488281 0.634552 0.0129568 0.694712 0.0390171C0.754872 0.0650773 0.809056 0.1032 0.853918 0.15103L6.5004 5.79103L12.1469 0.15103C12.1917 0.1032 12.2459 0.0650773 12.3061 0.0390171C12.3663 0.0129568 12.4311 -0.000488281 12.4967 -0.000488281C12.5622 -0.000488281 12.6271 0.0129568 12.6872 0.0390171C12.7474 0.0650773 12.8016 0.1032 12.8464 0.15103C12.8942 0.19592 12.9323 0.250138 12.9584 0.310336C12.9844 0.370533 12.9979 0.435434 12.9979 0.50103C12.9979 0.566627 12.9844 0.631527 12.9584 0.691725C12.9323 0.751923 12.8942 0.80614 12.8464 0.85103L6.85018 6.85103C6.80557 6.89907 6.75139 6.93723 6.69115 6.96306C6.63091 6.9889 6.56594 7.00183 6.5004 7.00103Z' fill='%23282828'/%3E%3C/svg%3E");
    background-size: 0.75rem 0.4375rem;
    background-position: right 1.0625rem center;
    font-size: 0.8125rem;
    padding-top: 1.375rem;
    padding-bottom: 0.375rem;
    -webkit-appearance: none;
    border-color: #282828;
    background-repeat: no-repeat; }
    .form-group-select select.custom-select::-ms-expand,
    .form-group-select select::-ms-expand {
      display: none; }
    .form-group-select select.custom-select.hover, .form-group-select select.custom-select:hover,
    .form-group-select select.hover,
    .form-group-select select:hover {
      border-color: #7E7E7E; }
    .form-group-select select.custom-select.disabled, .form-group-select select.custom-select:disabled,
    .form-group-select select.disabled,
    .form-group-select select:disabled {
      border-color: #D4D4D4;
      background-color: #F6F3F2;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='13' height='7' viewBox='0 0 13 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5004 7.00103C6.43487 7.00183 6.36989 6.9889 6.30965 6.96306C6.24941 6.93723 6.19524 6.89907 6.15062 6.85103L0.154354 0.85103C0.106553 0.80614 0.0684545 0.751923 0.0424105 0.691725C0.0163664 0.631527 0.00292969 0.566627 0.00292969 0.50103C0.00292969 0.435434 0.0163664 0.370533 0.0424105 0.310336C0.0684545 0.250138 0.106553 0.19592 0.154354 0.15103C0.199216 0.1032 0.2534 0.0650773 0.31356 0.0390171C0.37372 0.0129568 0.43858 -0.000488281 0.504136 -0.000488281C0.569692 -0.000488281 0.634552 0.0129568 0.694712 0.0390171C0.754872 0.0650773 0.809056 0.1032 0.853918 0.15103L6.5004 5.79103L12.1469 0.15103C12.1917 0.1032 12.2459 0.0650773 12.3061 0.0390171C12.3663 0.0129568 12.4311 -0.000488281 12.4967 -0.000488281C12.5622 -0.000488281 12.6271 0.0129568 12.6872 0.0390171C12.7474 0.0650773 12.8016 0.1032 12.8464 0.15103C12.8942 0.19592 12.9323 0.250138 12.9584 0.310336C12.9844 0.370533 12.9979 0.435434 12.9979 0.50103C12.9979 0.566627 12.9844 0.631527 12.9584 0.691725C12.9323 0.751923 12.8942 0.80614 12.8464 0.85103L6.85018 6.85103C6.80557 6.89907 6.75139 6.93723 6.69115 6.96306C6.63091 6.9889 6.56594 7.00183 6.5004 7.00103Z' fill='%23A9A9A9'/%3E%3C/svg%3E"); }
      .form-group-select select.custom-select.disabled ~ .custom-select-label,
      .form-group-select select.custom-select.disabled ~ .custom-select-helper, .form-group-select select.custom-select:disabled ~ .custom-select-label,
      .form-group-select select.custom-select:disabled ~ .custom-select-helper,
      .form-group-select select.disabled ~ .custom-select-label,
      .form-group-select select.disabled ~ .custom-select-helper,
      .form-group-select select:disabled ~ .custom-select-label,
      .form-group-select select:disabled ~ .custom-select-helper {
        color: #A9A9A9; }
    .form-group-select select.custom-select.is-invalid,
    .form-group-select select.is-invalid {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='13' height='7' viewBox='0 0 13 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5004 7.00103C6.43487 7.00183 6.36989 6.9889 6.30965 6.96306C6.24941 6.93723 6.19524 6.89907 6.15062 6.85103L0.154354 0.85103C0.106553 0.80614 0.0684545 0.751923 0.0424105 0.691725C0.0163664 0.631527 0.00292969 0.566627 0.00292969 0.50103C0.00292969 0.435434 0.0163664 0.370533 0.0424105 0.310336C0.0684545 0.250138 0.106553 0.19592 0.154354 0.15103C0.199216 0.1032 0.2534 0.0650773 0.31356 0.0390171C0.37372 0.0129568 0.43858 -0.000488281 0.504136 -0.000488281C0.569692 -0.000488281 0.634552 0.0129568 0.694712 0.0390171C0.754872 0.0650773 0.809056 0.1032 0.853918 0.15103L6.5004 5.79103L12.1469 0.15103C12.1917 0.1032 12.2459 0.0650773 12.3061 0.0390171C12.3663 0.0129568 12.4311 -0.000488281 12.4967 -0.000488281C12.5622 -0.000488281 12.6271 0.0129568 12.6872 0.0390171C12.7474 0.0650773 12.8016 0.1032 12.8464 0.15103C12.8942 0.19592 12.9323 0.250138 12.9584 0.310336C12.9844 0.370533 12.9979 0.435434 12.9979 0.50103C12.9979 0.566627 12.9844 0.631527 12.9584 0.691725C12.9323 0.751923 12.8942 0.80614 12.8464 0.85103L6.85018 6.85103C6.80557 6.89907 6.75139 6.93723 6.69115 6.96306C6.63091 6.9889 6.56594 7.00183 6.5004 7.00103Z' fill='%23ED3535'/%3E%3C/svg%3E");
      background-size: 0.75rem 0.4375rem;
      background-position: right 1.0625rem center;
      border-color: #ED3535; }
      .form-group-select select.custom-select.is-invalid.hover, .form-group-select select.custom-select.is-invalid:hover,
      .form-group-select select.is-invalid.hover,
      .form-group-select select.is-invalid:hover {
        border-color: #ED3535; }
      .form-group-select select.custom-select.is-invalid ~ .custom-select-helper,
      .form-group-select select.is-invalid ~ .custom-select-helper {
        color: #ED3535; }
    .form-group-select select.custom-select.sort-order,
    .form-group-select select.sort-order {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='13' height='7' viewBox='0 0 13 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5004 7.00103C6.43487 7.00183 6.36989 6.9889 6.30965 6.96306C6.24941 6.93723 6.19524 6.89907 6.15062 6.85103L0.154354 0.85103C0.106553 0.80614 0.0684545 0.751923 0.0424105 0.691725C0.0163664 0.631527 0.00292969 0.566627 0.00292969 0.50103C0.00292969 0.435434 0.0163664 0.370533 0.0424105 0.310336C0.0684545 0.250138 0.106553 0.19592 0.154354 0.15103C0.199216 0.1032 0.2534 0.0650773 0.31356 0.0390171C0.37372 0.0129568 0.438581 -0.000488281 0.504136 -0.000488281C0.569692 -0.000488281 0.634552 0.0129568 0.694712 0.0390171C0.754872 0.0650773 0.809056 0.1032 0.853918 0.15103L6.5004 5.79103L12.1469 0.15103C12.1917 0.1032 12.2459 0.0650773 12.3061 0.0390171C12.3663 0.0129568 12.4311 -0.000488281 12.4967 -0.000488281C12.5622 -0.000488281 12.6271 0.0129568 12.6872 0.0390171C12.7474 0.0650773 12.8016 0.1032 12.8464 0.15103C12.8942 0.19592 12.9323 0.250138 12.9584 0.310336C12.9844 0.370533 12.9979 0.435434 12.9979 0.50103C12.9979 0.566627 12.9844 0.631527 12.9584 0.691725C12.9323 0.751923 12.8942 0.80614 12.8464 0.85103L6.85018 6.85103C6.80557 6.89907 6.75139 6.93723 6.69115 6.96306C6.63091 6.9889 6.56594 7.00183 6.5004 7.00103Z' fill='%23282828'/%3E%3C/svg%3E");
      border: 0;
      background-color: transparent;
      padding: 10px; }

i {
  display: inline-block; }
  i svg,
  i img {
    width: 100%;
    height: 100%; }

.nav.nav-tabs {
  border-bottom: 1px solid #7E7E7E; }
  .nav.nav-tabs .nav-item {
    -ms-flex: 1 1 0px;
        flex: 1 1 0px; }
    .nav.nav-tabs .nav-item .nav-link {
      font-family: Graphik, sans-serif;
      font-weight: normal;
      font-size: 11px;
      line-height: 20px;
      text-transform: uppercase;
      color: #7E7E7E !important;
      text-decoration: none;
      padding: 17px 10px;
      background: transparent; }
      .nav.nav-tabs .nav-item .nav-link.active {
        color: #282828 !important;
        border-bottom: 3px solid #282828; }

.tab-content {
  padding: 30px 0px; }

.nav-item .nav-link:hover, .nav-item .nav-link:focus {
  background-color: transparent;
  color: #fff; }
